// @flow
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Header from "../header"
import type {SocialMedia} from '../../pages/index';
import "./layout.css"

type Props = {
    email: string,
    links: SocialMedia,
    children: any
}
export const Layout = ({ children, links, email }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer>
            <div className={'row layout--footer'}>
              <OutboundLink className={'column--3 col-centered'} href={links.linkedIn}>LinkedIn</OutboundLink>
              <OutboundLink className={'column--3 col-centered'} href={links.github}>Github</OutboundLink>
              <OutboundLink className={'column--3 col-centered'} href={`mailto:${email}`}>Contact</OutboundLink>
            </div>
          </footer>
        </div>
      </>
    )}
  />
);